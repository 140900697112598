<template>
  <div class="rewiews wrap px-md-8 px-3">
    <div class="mt-6 d-flex flex-wrap">
      <div class="h2">Notifications</div>
      <v-spacer></v-spacer>
      <v-btn v-if="data.results.find((item) => item.seen == false)" @click="readAllNotifi" text>Read all</v-btn>
    </div>
    <v-card tile flat v-if="!data.results.length" class="d-flex align-center justify-center" height="75vh">
      <div>
        <v-img height="140" contain src="@/assets/img/epmty-notifi.svg"></v-img>
        <div class="h4 mt-7">There are no notifications</div>
      </div>
    </v-card>
    <div v-for="i in data.results" :key="i.id" class="link" @click="notifi(i)">
      <div v-if="i.type == 'lesson_start_soon_v1'">
        <div class="d-flex align-center mb-1">
          <div class="h8 text--text"><span v-if="!i.seen" class="url--text h5">•</span>Your lesson will start in 10 minutes</div>
          <v-spacer></v-spacer>
          <div class="h10 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
        </div>
        <div class="h8">{{ i.data.course.title }}: {{ i.data.lesson_title }}. Student: {{ i.data.student.full_name }}</div>
      </div>
      <div v-if="i.type == 'lesson_date_time_changed_v1'">
        <div class="d-flex align-center mb-1">
          <div class="h8 text--text"><span v-if="!i.seen" class="url--text h5">•</span>Lesson time changed</div>
          <v-spacer></v-spacer>
          <div class="h10 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
        </div>
        <div class="h8">
          {{ i.data.course.title }}: {{ i.data.lesson_title }} time changed to {{ new Date(i.data.begin_at).toLocaleString() }}. Student:
          {{ i.data.student.full_name }}
        </div>
      </div>
      <div v-if="i.type == 'course_purchased_v1'">
        <div class="d-flex align-center mb-1">
          <div class="h8 text--text"><span v-if="!i.seen" class="url--text h5">•</span>New course booking</div>
          <v-spacer></v-spacer>
          <div class="h10 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
        </div>
        <div class="h8">
          {{ i.data.course.title }} has booked by {{ i.data.student.full_name }}. Purchased option: {{ i.data.option.title }}. Lessons:
          {{ i.data.option.lessons }}
        </div>
      </div>
      <div v-if="i.type == 'course_new_review_v1'">
        <div class="d-flex align-center mb-1">
          <div class="h8 text--text"><span v-if="!i.seen" class="url--text h5">•</span>New course review</div>
          <v-spacer></v-spacer>
          <div class="h10 text--text">{{ new Date(i.created_at).toLocaleString() }}</div>
        </div>
        <div class="h8">{{ i.data.student.full_name }} has submitted review to {{ i.data.course.title }} course.</div>
      </div>
      <v-divider class="hover mt-3 mb-2"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch('getNotificationsList', this.page);
    },
    notifi(i) {
      if (i.type == 'lesson_start_soon_v1') {
        this.$router.push(`/students/${i.data.student.id}`);
      } else if (i.type == 'lesson_date_time_changed_v1') {
        this.$router.push(`/students/${i.data.student.id}`);
      } else if (i.type == 'course_purchased_v1') {
        this.$router.push(`/course/${i.data.course.id}`);
      } else {
        this.$router.push(`/course/${i.data.course.id}`);
      }
      if (!i.seen) {
        this.$store.dispatch('reedNotification', i.id);
      }
    },
    async readAllNotifi() {
      await this.$store.dispatch('reedAllNotificationsList').then(() => {
        this.getData();
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.notificationsList;
    },
  },
};
</script>

<style>
.rewiews {
  max-width: 645px;
}
</style>
